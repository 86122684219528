import AOS from "aos";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Contact() {
  AOS.init();
  const openMailer = () => {
    window.open("mailto:kayleemburch@gmail.com?subject=&body=");
  };

  return (
    <Container className="centered-text">
      <Row sm={1}>
        <h1>Interested in contacting me?</h1>
      </Row>
      <Row
        style={{ marginTop: "40px" }}
        data-aos="fade-right"
        data-aos-duration="1000"
        sm={12}
      >
        <Col md="auto">
          <Button
            variant="outlined"
            className="button-design"
            onClick={openMailer}
            startIcon={<EmailIcon />}
            size="large"
          >
            Kayleemburch@gmail.com
          </Button>
        </Col>
        <Col md="auto">
          <Button
            variant="outlined"
            className="button-design"
            href="https://www.linkedin.com/in/kaylee-burch-419a671b4/"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<LinkedInIcon />}
            size="large"
          >
            LinkedIn
          </Button>
        </Col>
        <Col md="auto">
          <Button
            variant="outlined"
            className="button-design"
            href="https://github.com/kayleeburch"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<GitHubIcon />}
            size="large"
          >
            Github
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
