import NavBar from "../components/NavBar/NavBar";
import About from "./About";
import Experience from "./Experience";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import kaylee_customized from "../images/kaylee_customized.png";

export default function Home() {
  return (
    <div className="page-background" id="home">
      <div>
        <NavBar />
        <div className="not-overlapping-nav"></div>
        <div className="centered-text full-height">
          <Container fluid>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12} md={12} lg={6} xxl={6}>
                <img
                  src={kaylee_customized}
                  alt="kaylee_customized"
                  className="kaylee_photo_home"
                  style={{ width: "500px" }}
                />
              </Col>
              <Col className="header-text" sm={12} md={12} lg={6} xxl={6}>
                <Row>
                  <h1>
                    <span style={{ color: "#72efdd" }}>{"<h1>"}</span>Hello!
                    <span style={{ color: "#72efdd" }}>{"</h1>"}</span>
                  </h1>
                </Row>
                <Row>
                  <h1>
                    I'm <span style={{ color: "#72efdd" }}>Kaylee Burch</span>,
                  </h1>
                </Row>
                <Row>
                  <h1>Software Engineer and Front-End Developer</h1>
                </Row>
                <Row xl={1} style={{ marginTop: "30px" }} className="button-row">
                  <Button
                    style={{ width: "auto" }}
                    variant="outlined"
                    className="button-design"
                    startIcon={<DownloadIcon />}
                    href="./KayleeBurch_Resume2024.pdf"
                    download
                  >
                    Download My Resume
                  </Button>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ height: "88px" }} id="about"></div>
        <div className="centered-text large-spacing-between">
          <About />
        </div>
        <div style={{ height: "88px" }} id="portfolio"></div>
        <div className="centered-text large-spacing-between">
          <Portfolio />
        </div>
        <div style={{ height: "88px" }} id="experience"></div>
        <div>
          <Experience />
        </div>
        <div className="centered-text full-height" id="contact">
          <Contact />
        </div>
      </div>
    </div>
  );
}
