import * as React from "react";
import "./AccordionComp.css";
import Accordion from "react-bootstrap/Accordion";
import WorkIcon from "@mui/icons-material/Work";
import LaunchIcon from "@mui/icons-material/Launch";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import navy from "../../images/navy_logo.png";
import quevera from "../../images/quevera_logo.jpeg";

const experience = [
  {
    name: "Quevera",
    date: "Sep 2022 - Present",
    role: "Full-Stack Software Engineer",
    location: "Columbia, MD",
    website: "https://quevera.com/",
    logo: quevera,
    skills: [
      "AWS",
      "React",
      "Django",
      "PostgreSQL",
      "Docker",
      "Microsoft SQL Server",
      "React Testing Library",
      "Mock Service Worker",
      "Mentoring",
      "Agile",
    ],
    about:
      "Collaborated with a small team of developers to maintain and modernize a legacy web application for a client. Successfully managed a full migration to the cloud, leveraging React, Django, and AWS to create a robust full-stack solution. Provided mentorship to junior developers and actively participated in an agile workflow to ensure efficient project delivery.",
  },
  {
    name: "Code Platoon's Immersive, Full-Stack Software Engineering Program",
    date: "May 2022 - September 2022",
    role: "Graduate",
    location: "Remote (Chicago, IL)",
    website: "https://www.codeplatoon.org/",
    logo: "https://www.codeplatoon.org/wp-content/uploads/2018/10/CP-logo-2018-abbrev-1.png",
    skills: [
      "JavaScript",
      "Python",
      "React",
      "Django",
      "REST",
      "HTML/CSS",
      "Git/Github",
      "OOP",
      "Pair Programming",
    ],
    about:
      "As part of a 15-week course, dedicated over 1,000 hours learning how to build full-stack web applications. Utilized Github and pair programming to collaborate with team members while following an Agile workflow.",
  },
  {
    name: "FullStack Labs",
    date: "Oct 2021 - Dec 2021",
    role: "Quality Assurance Professional",
    location: "Remote (Sacramento, CA)",
    website: "https://www.fullstacklabs.co/",
    logo: "https://assets-global.website-files.com/5d9bc5d562ffc2869b470941/5e013c6d193e334919d6e371_logo-fsl-light-gradient.svg",
    skills: ["Jira", "Manual Testing", "Agile", "Android Studio", "Xcode"],
    about:
      "Manually tested on a variety of Operating Systems such as Android, iOS, Mac OS, and Windows. Followed an Agile workflow and participated in daily stand-ups and end of sprint retrospectives. Used Jira as a PMS, and created 10+ tickets daily across 4 different projects simultaneously for defects found.",
  },
  {
    name: "ReSupply",
    date: "Feb 2021 - July 2021",
    role: "Intern",
    location: "Remote (Boston, MA)",
    website: "https://resupplyapp.com/",
    logo: "https://assets-global.website-files.com/5d9bc5d562ffc2869b470941/5e013c6d193e334919d6e371_logo-fsl-light-gradient.svg",
    skills: ["Scrum", "Project Life Cycle", "ClickUp", "Retool"],
    about:
      "Gained significant start-up experience interning at ReSupply. Worked under the PM learning Project Life Cycle and Scrum. Worked as Quality Assurance, manually testing and creating tickets via PMS, ClickUp",
  },
  {
    name: "United States Navy",
    date: "July 2017 - July 2021",
    role: "IT (Information Technology Specialist)",
    location: "Norfolk, VA",
    website: "https://www.navy.com/",
    logo: navy,
    skills: ["Leadership", "Effective Communication", "Technical Troubleshooting", "Adaptability"],
    about:
      "As part of a 15-week course, dedicated over 1,000 hours learning how to build full-stack web applications. Utilized Github and pair programming to collaborate with team members while following an Agile workflow.",
  },
];

export default function ControlledAccordions() {
  return (
    <div>
      <Accordion className="accordionComp">
        {experience.map((job, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <h5 style={{ color: "white" }}>
                <span style={{ color: "#7D4CE1" }}>{job.role}</span> - {job.name}
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <Col>
                  <Row>
                    <Col>
                      <Row>
                        <Col lg={1}>
                          <WorkIcon />
                        </Col>
                        <Col>
                          <h5>{job.location}</h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col lg={1}>
                          <LaunchIcon />
                        </Col>
                        <Col>
                          <a href={job.website} target="_blank" rel="noopener noreferrer">
                            {job.website}
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <p>{job.about}</p>
                  </Row>
                  <Row>
                    <Col>
                      <h5>{job.date}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Stack
                      direction="row"
                      spacing={1}
                      className="chip-col"
                      style={{ flexWrap: "wrap" }}
                    >
                      {job.skills.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          variant="outlined"
                          style={{
                            marginTop: "20px",
                            borderColor: "#72efdd",
                            color: "#72efdd",
                          }}
                        />
                      ))}
                    </Stack>
                  </Row>
                </Col>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
