import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cards from "../components/Cards/Cards";

export default function Portfolio() {
  return (
    <Container>
      <Row xs={1} sm={1} md={1} lg={2}>
        <Col style={{ margin: "auto", textAlign: "center" }}>
          <h1 className="add-bottom-space-small">Portfolio</h1>
        </Col>
        <Col>
          <Cards />
        </Col>
      </Row>
    </Container>
  );
}
