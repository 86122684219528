import "./Cards.css";
import AOS from "aos";
import { useState } from "react";
import "aos/dist/aos.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import gamenight from "../../images/gamenight_logo.png";
import placeshare from "../../images/placeshare_logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalComp from "../ModalComp/ModalComp";

import autocomplete from "../../images/placeshare_img/autocomplete_view.png";
import signup from "../../images/placeshare_img/sign_up_view.png";
import home from "../../images/placeshare_img/login_screen.png";
import delete_view from "../../images/placeshare_img/delete_view.png";
import edit_account from "../../images/placeshare_img/edit_account_view.png";
import email_view from "../../images/placeshare_img/email_view.png";
import google_map from "../../images/placeshare_img/google_maps_view.png";
import map_response from "../../images/placeshare_img/map_response.png";
import offCanvas_view from "../../images/placeshare_img/offCanvas_view.png";
import places_list from "../../images/placeshare_img/places_list_view.png";

export default function ActionAreaCard() {
  AOS.init();

  const [itemData, setItemData] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClick = (list, name) => {
    if (name === "GameNight") {
      window.open("https://youtu.be/RJpUSdzbQXo");
    } else {
      setItemData(list);
      handleOpen();
    }
  };

  const portfolio = [
    {
      image: gamenight,
      name: "GameNight",
      tech: "Django, React, AWS, PrimeReact, Bootstrap, PostgreSQL, Stream Chat API, FullCalendar API, Jira, Github",
      summary:
        "Worked collaboratively with a team of 5 other developers to create GameNight, a website where you can plan gaming events with your friends!",
      imageList: [],
    },
    {
      image: placeshare,
      name: "PlaceShare",
      tech: "Django, React, Bootstrap, PostgreSQL, Google Cloud Platform, Google Maps API, EmailJS API",
      summary:
        "Solo project created with React, Django, and Google Cloud Platform. Add your favorite places to lists you create and share with friends via email.",
      imageList: [
        {
          img: home,
          title: "Home Screen, Sign up or Log in",
        },
        {
          img: signup,
          title: "Sign up page with password/email verification",
        },
        {
          img: google_map,
          title: "Map view page where users can find address to add to a list",
        },
        {
          img: autocomplete,
          title:
            "Autocomplete functionality for addresses near user's ZIP inputted on sign up",
        },
        {
          img: map_response,
          title:
            "Upon address selection, user is prompted to add address to Places or cancel",
        },
        {
          img: offCanvas_view,
          title:
            "On selection of Add to Places, user can input title, description, and select list",
        },
        {
          img: places_list,
          title:
            "List view where user can create and edit lists that will hold places",
        },
        {
          img: delete_view,
          title: "Ability to edit and delete lists",
        },
        {
          img: email_view,
          title: "Email places to your friends, uses EmailJS API",
        },
        {
          img: edit_account,
          title: "Users can edit their account information",
        },
      ],
    },
  ];

  return (
    <Container>
      <Row xs={1} md={2}>
        {portfolio.map((project, index) => (
          <Col key={index} data-aos="fade-up" data-aos-duration="3000">
            {itemData && (
              <ModalComp itemData={itemData} setOpen={setOpen} open={open} />
            )}
            <Card
              style={{ backgroundColor: "#212020", color: "#fff" }}
              onClick={() => handleClick(project.imageList, project.name)}
              className="spacing-between"
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={project.image}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {project.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ color: "#fff" }}
                  >
                    {project.summary}
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="body2"
                    color="text.secondary"
                    style={{ color: "#fff", marginTop: "20px" }}
                  >
                    <br></br>
                    <span style={{ fontSize: "18px" }}>Tech Used: </span>
                    <p>{project.tech}</p>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
