import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AccordionComp from "../components/AccordionComp/AccordionComp";

export default function Experience() {
  return (
    <Container className="centered-text">
      <Row style={{ marginBottom: "30px" }}>
        <Col>
          <h1>Professional Experience</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <AccordionComp />
        </Col>
      </Row>
    </Container>
  );
}
