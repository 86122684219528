import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ImagesList from "../ImagesList/ImagesList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ setOpen, open, itemData }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "0" }} className="wider-on-mobile">
          <ImagesList itemData={itemData} />
        </Box>
      </Modal>
    </div>
  );
}
