import "./NavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/kaylee_logo.png";

export default function NavBar() {
  return (
    <>
      <Navbar
        bg="clear"
        variant="dark"
        fixed="top"
        className="nav-color"
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" width="150px" className="logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className="nav-font" eventKey="1">
                Home
              </Nav.Link>
              <Nav.Link href="#about" className="nav-font" eventKey="2">
                About
              </Nav.Link>
              <Nav.Link href="#portfolio" className="nav-font" eventKey="3">
                Portfolio
              </Nav.Link>
              <Nav.Link href="#experience" className="nav-font" eventKey="4">
                Experience
              </Nav.Link>
              <Nav.Link href="#contact" className="nav-font" eventKey="5">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
