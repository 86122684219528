import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import coding_backdrop from "../images/coding_backdrop.png";
import ImageCarousel from "../components/ImageCarousel/ImageCarousel";

export default function About() {
  return (
    <Container>
      <Row>
        <Col md={12} lg={8}>
          <Row>
            <h1>About Me</h1>
          </Row>
          <Row>
            <h3>Navy Veteran turned Software Engineer</h3>
          </Row>
          <Row>
            <p style={{ color: "white", fontSize: "24px", marginTop: "5px" }}>Hello there!</p>
            <p style={{ color: "white", fontSize: "24px" }}>
              A little bit about me, <span style={{ color: "#7E4DE2" }}>I love programming!</span>
            </p>
            <p style={{ color: "white", fontSize: "24px" }}>
              I started my technical journey about 4 years ago after my departure from the Navy. At
              that time, I could barely type, and while taking typing courses, I decided to push the
              envelope and started looking into development as a career. I knew I loved problem
              solving (still do!) and I had to learn more. I was hooked. 4 years later, I am still
              hooked and I am still enjoying working in development everyday!{" "}
              <span style={{ color: "#7E4DE2" }}>
                When we 'push the envelope' and surprise ourselves with what we are capable of,
                great things happen.
              </span>{" "}
              This is an ideology I try and live by and has shaped who I am today!
            </p>
          </Row>
        </Col>
        <Col md={12} lg={4}>
          <img
            src={coding_backdrop}
            alt="coding_backdrop"
            className="kaylee_photo_home"
            style={{ width: "600px" }}
          />
          {/* <Row>
            <div className="centered-text">
              <ImageCarousel />
            </div>
          </Row> */}
        </Col>
      </Row>
    </Container>
  );
}
